import * as React from "react"
import { Link } from "@reach/router"

class Navbar extends React.Component {
  listener = null;
  state = {
    nav: false,
    collapse: true
  }

  componentDidMount() {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  handleScroll = () => {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      if (window.pageYOffset > 400) {
        if (!this.state.nav) {
          this.setState({ nav: true });
        }
      } else {
        if (this.state.nav) {
          this.setState({ nav: false });
        }
      }
    }

  }

  toggleCollapse = () => {
    this.setState({ collapse: !this.state.collapse })
  }

  render() {
    return (
      <header>
        <nav className={`navbar navbar-expand-lg navbar-light bg-light ${this.state.nav && 'navbar-fixed'}`}>
          <div className="container">
            <a className="navbar-brand fw-extrabold align-items-center me-0" href="https://ui.glass">
              <svg width="30" className="me-2" viewBox="0 0 91 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5874 13.4898L65.2574 7.81319C70.7431 7.17338 75.7088 11.1017 76.3486 16.5874L82.4886 69.2305C83.1284 74.7162 79.2 79.6819 73.7144 80.3217L25.0443 85.9982C19.5586 86.6381 14.5929 82.7097 13.9531 77.2241L7.81317 24.5809C7.17336 19.0952 11.1017 14.1296 16.5874 13.4898Z" stroke="url(#paint0_linear)" strokeWidth="12" />
                <path d="M30.1914 75.5187L70.4386 70.8032C71.5328 70.675 72.3173 69.6861 72.1931 68.5914L66.5981 19.2546C66.4077 17.5758 64.3505 16.8748 63.1747 18.088L40.2828 41.7081C40.0962 41.9007 39.9501 42.1289 39.8535 42.3791L28.0932 72.8113C27.5504 74.2159 28.6959 75.6939 30.1914 75.5187Z" fill="url(#paint1_linear)" />
                <defs>
                  <linearGradient id="paint0_linear" x1="40.2273" y1="4.69187" x2="50.0744" y2="89.1196" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5EBB79" />
                    <stop offset="1" stopColor="#00AB82" />
                  </linearGradient>
                  <linearGradient id="paint1_linear" x1="69.5258" y1="43.0571" x2="23.9489" y2="47.8474" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5BBB7A" />
                    <stop offset="1" stopColor="#06AC82" />
                  </linearGradient>
                </defs>
              </svg>
              Glass UI
            </a>
            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button> */}
            {/* className="collapse navbar-collapse" */}
            <div className="d-lg-flex align-items-center" id="navbarNav">
              {/* <ul className="navbar-nav me-auto ms-3">
                  <li className="nav-item">
                    <a className="nav-link" href="https://ui.glass" target="_blank">CSS Library</a>
                  </li>
                  <li className="nav-item">
                    <Link to="/" className="nav-link">Generator</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://themesberg.com/blog/glassmorphism/tutorial" target="_blank">About</a>
                  </li>
                </ul> */}
              <div id="revue-embed">
                <form action="https://www.getrevue.co/profile/glass-ui/add_subscriber" className="d-none d-lg-inline-block ms-auto" method="post" id="revue-form" name="revue-form" target="_blank">
                  <div className="d-flex align-items-center">
                    <div class="revue-form-group d-flex align-items-center flex-wrap me-3">
                      <label for="member_email" className="small text-muted me-2">Sign up to Glass UI:</label>
                      <input class="revue-form-field form-control navbar-form fmxw-200" placeholder="Email address" type="email" name="member[email]" id="member_email" required/>
                    </div>
                    <div class="revue-form-actions">
                      <input className="btn btn-sm btn-primary rounded-1 py-2" type="submit" value="Subscribe" name="member[subscribe]" id="member_submit" />
                    </div>
                  </div>
                </form>
              </div>
              <a href="https://github.com/themesberg/glassmorphism" className="ms-4" target="_blank">
                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" className="icon-navbar" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path></svg>
              </a>
              <a href="https://twitter.com/zoltanszogyenyi" rel="nofollow" className="ms-3" target="_blank">
                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" className="icon-navbar" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
              </a>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
export default Navbar
