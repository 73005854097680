import * as React from "react"
import { Link } from "@reach/router"

// markup
const Footer = () => {
    return (
        <footer className="py-5 pt-lg-6 px-2 bg-soft">
            <div className="container">
                <div className="row pb-5 pb-lg-6 border-bottom border-gray-200">
                    <div className="col-lg-3 mb-5 mb-lg-0">
                        <a href="https://ui.glass" className="footer-brand fw-extrabold d-inline-flex align-items-center mb-3">
                            <svg width="30" className="me-2" viewBox="0 0 91 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5874 13.4898L65.2574 7.81319C70.7431 7.17338 75.7088 11.1017 76.3486 16.5874L82.4886 69.2305C83.1284 74.7162 79.2 79.6819 73.7144 80.3217L25.0443 85.9982C19.5586 86.6381 14.5929 82.7097 13.9531 77.2241L7.81317 24.5809C7.17336 19.0952 11.1017 14.1296 16.5874 13.4898Z" stroke="url(#paint0_linear)" strokeWidth="12" />
                                <path d="M30.1914 75.5187L70.4386 70.8032C71.5328 70.675 72.3173 69.6861 72.1931 68.5914L66.5981 19.2546C66.4077 17.5758 64.3505 16.8748 63.1747 18.088L40.2828 41.7081C40.0962 41.9007 39.9501 42.1289 39.8535 42.3791L28.0932 72.8113C27.5504 74.2159 28.6959 75.6939 30.1914 75.5187Z" fill="url(#paint1_linear)" />
                                <defs>
                                    <linearGradient id="paint0_linear" x1="40.2273" y1="4.69187" x2="50.0744" y2="89.1196" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#5EBB79" />
                                        <stop offset="1" stopColor="#00AB82" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear" x1="69.5258" y1="43.0571" x2="23.9489" y2="47.8474" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#5BBB7A" />
                                        <stop offset="1" stopColor="#06AC82" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            Glass UI
                        </a>
                        <p>Glass UI is a free and open-source CSS library based on the glassmorphism design specifications.</p>
                    </div>
                    <div className="col-lg-2 col-6 ms-lg-auto mb-lg-0 mb-5">
                        <h6 className="small text-uppercase text-gray-400 mb-3">Resources</h6>
                        <ul className="flex-column nav">
                            <li className="nav-item">
                                <a className="nav-link" href="https://ui.glass/">Glassmorphism</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://themesberg.com?ref=glass-ui-generator" target="_blank">Themesberg</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://flowbite.com" target="_blank">Flowbite</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://themesberg.com/knowledge-center?ref=glass-ui-generator" target="_blank">Knowledge center</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-2 col-6 mb-lg-0 mb-5">
                        <h6 className="small text-uppercase text-gray-400 mb-3">Help and support</h6>
                        <ul className="flex-column nav">
                            <li className="nav-item">
                                <a className="nav-link" href="https://themesberg.com/blog/glassmorphism/tutorial?ref=glass-ui-generator" target="_blank">
                                    Tutorial
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="https://themesberg.com/contact?ref=glass-ui-generator" target="_blank">
                                    Contact us
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="https://themesberg.com/custom-development?ref=glass-ui-generator" target="_blank">
                                    Custom development
                                </a>
                            </li>

                        </ul>
                    </div>

                    <div className="col-lg-2 col-6 mb-lg-0 mb-2">
                        <h6 className="small text-uppercase text-gray-400 mb-3">Follow us</h6>
                        <ul className="flex-column nav">
                            <li className="nav-item">
                                <a className="nav-link" href="https://twitter.com/themesberg?ref=glass-ui-generator" rel="nofollow noopener" target="_blank">
                                    Twitter
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="https://github.com/themesberg?ref=glass-ui-generator" rel="nofollow noopener" target="_blank">
                                    Github
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="https://www.facebook.com/themesberg?ref=glass-ui-generator" rel="nofollow noopener" target="_blank">
                                    Facebook
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="https://linkedin.com/company/themesberg?ref=glass-ui-generator" rel="nofollow noopener" target="_blank">
                                    LinkedIn
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-2 col-6 mb-lg-0 mb-2">
                        <h6 className="small text-uppercase text-gray-400 mb-3">Legal</h6>
                        <ul className="flex-column nav">
                            <li className="nav-item">
                                <a className="nav-link" href="https://themesberg.com/terms-and-conditions?ref=glass-ui-generator" target="_blank">
                                    Terms and conditions
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="https://themesberg.com/privacy-policy?ref=glass-ui-generator" target="_blank">
                                    Privacy policy
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="https://themesberg.com/licensing?ref=glass-ui-generator" target="_blank">
                                    EULA
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-12 text-center">
                        <small>© 2019-{new Date().getFullYear()} <a href="https://themesberg.com?ref=glass-ui-generator" className="hover-text-underline" target="_blank">Themesberg</a>. All Rights Reserved.</small>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer

